import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function urlValidator(): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (!c.value) {
      return null;
    }

    try {
      new URL(c.value);
      return null;
    } catch {
      return { invalidUrl: true };
    }
  };
}
